import { application } from 'services/core'

function ListingCarouselSection () {
  const elements = {}

  const initializeSelectors = () => {
    elements.addStore = document.querySelector('#add_listing_stores')
    elements.listingStoreIds = document.querySelector('#listing_carousel_section_store_ids')
    elements.selectedServiceType = document.querySelector('#listing_section_service_type')
    elements.rankSelectedServiceType = document.querySelector('#listing_service_type_rank')
    elements.sortedListingSections = document.querySelector('#listing_rank_ids')
    elements.submitRankButton = document.querySelector('#submit-listing-ranks')
    elements.displayTabs = document.querySelectorAll('.listing_section_component')
    elements.pageTitle = document.querySelector('#listing_section_page_title')
    elements.closeModal = document.querySelector('#closeSuccessModal')
    elements.listingSectionRank = document.querySelector('#listing_section_rank')
    elements.submitButton = document.querySelector('#listing_carousel_section_submit_btn')
    elements.configurationType = document.querySelector('#listing_section_configuration_type')
  }

  const initializeModalSelectors = () => {
    elements.associatedStoreIds = document.querySelector('#associated_store_ids')
    elements.searchQuery = document.querySelector('#search_query')
    elements.storesTable = document.querySelector('#listing_stores_table')
    elements.associatedStoresTable = document.querySelector('#associated_listing_stores')
    fillServiceTypeId()
  }

  const initializeListeners = () => {
    $(document).on('click', '.add_to_listing_section', associateStores)
    $(document).on('click', '.remove_from_listing_section', removeAssociatedStores)
    $(document).on('click', '#listing-clear-search', clearSearchQuery)
    $(document).on('click', '#submit-listing-ranks', updateListingRanks)
    $(document).on('change', '#listing_service_type_rank', associateListingRanks)
    $('#listing_section_configuration_type').on('change', toggleListingFields)
    $(elements.addStore).on('click', openStoreModal)
    $(elements.rankSelectedServiceType).on('change', disableRankSubmitBtn)
    $(elements.displayTabs).on('click', changeActiveTab)
    $(elements.closeModal).on('click', reloadPage)
    $(elements.selectedServiceType).on('change', calculateExistingRanks)
    $(elements.submitButton).on('click', restrictInCaseOfEmptyFields)
  }

  const clearSearchQuery = () => {
    elements.searchQuery.value = ''
  }

  const toggleListingFields = () => {
    const selectedConfigurationType = $('#listing_section_configuration_type').val()
    switch (selectedConfigurationType) {
      case 'special_stores':
        $('#select_listing_stores').removeClass('hidden')
        $('#select_listing_rating').addClass('hidden')
        $('#select_listing_stores').attr('required', true)
        break
      case 'rating_based_stores':
        $('#select_listing_rating').removeClass('hidden')
        $('#select_listing_stores').addClass('hidden')
        $('#select_listing_rating').attr('required', true)
        break
      default:
        $('#select_listing_rating').addClass('hidden')
        $('#select_listing_stores').addClass('hidden')
        break
    }
  }

  const initializeSortableListingTable = () => {
    $('.sortable-list').sortable({
      connectWith: '.sortable-list',
      placeholder: 'sortable-placeholder',
      update: (event, ui) => {
        const childrens = event.target.querySelectorAll('.sortable-item')
        let i = 1
        const sortedItems = [...childrens].map((el) => {
          const lastColumn = el.querySelector('td:last-child')
          lastColumn.innerHTML = i++
          return Number(el.dataset.recordId)
        })
        $('#listing_rank_ids').val(JSON.stringify(sortedItems))
      }
    }).disableSelection()
  }

  const changeActiveTab = (event) => {
    localStorage.setItem('activeTab', event.target.closest('a').getAttribute('href'))
    const componentString = event.target.textContent

    let title = ''
    switch (componentString) {
      case 'Listing Section Rank':
        title = 'Listing Section Rank'
        break
      case 'Listing sections':
        title = 'Listing Carousel sections'
        break
    }
    elements.pageTitle.innerHTML = title
  }

  const preSelectActiveTab = () => {
    const activeTabLink = localStorage.getItem('activeTab')
    const activeTab = $(`#listing_section_tabs a[href="${activeTabLink}"]`)

    if (activeTabLink && activeTab[0]) {
      activeTab.tab('show')
      elements.pageTitle.innerHTML = 'Listing Carousel sections'
    }
  }

  const fillServiceTypeId = () => {
    const serviceTypeId = elements.selectedServiceType.value

    $('#service_type_id').val(serviceTypeId)
  }

  const disableRankSubmitBtn = () => {
    if ($('#listing_service_type_rank').val() === '') {
      $('#submit-listing-ranks').attr('disabled', true)
    } else {
      $('#submit-listing-ranks').attr('disabled', false)
    }
  }

  const associateStores = (event) => {
    const selectedElement = event.target
    let existingStoreIds = elements.listingStoreIds.value
    if (existingStoreIds === '') {
      existingStoreIds = []
    } else {
      existingStoreIds = JSON.parse(existingStoreIds)
    }
    const itemIndex = existingStoreIds.indexOf(selectedElement.dataset.id)

    $(elements.storesTable)
      .DataTable()
      .row(selectedElement.closest('tr'))
      .remove()
      .draw()

    if (itemIndex > -1) {
      return
    }

    $(elements.associatedStoresTable)
      .DataTable()
      .row
      .add([
        selectedElement.dataset.id,
        selectedElement.dataset.name,
        `<a href='javascript:;' class='remove_from_listing_section'
          data-id=${selectedElement.dataset.id}
          data-name=${selectedElement.dataset.name}>
          Remove
         </a>`
      ])
      .draw()

    existingStoreIds.push(selectedElement.dataset.id)

    if (existingStoreIds.length) {
      existingStoreIds = JSON.stringify(existingStoreIds)
    } else {
      existingStoreIds = ''
    }

    elements.listingStoreIds.value = existingStoreIds
    elements.associatedStoreIds.value = existingStoreIds
  }

  const removeAssociatedStores = (event) => {
    event.stopImmediatePropagation()
    const selectedElement = event.target

    $(elements.associatedStoresTable)
      .DataTable()
      .row(selectedElement.closest('tr'))
      .remove()
      .draw()

    $(elements.storesTable)
      .DataTable()
      .row
      .add([
        selectedElement.dataset.id,
        selectedElement.dataset.name,

        `<a href='javascript:;' class='add_to_listing_section'
        data-id=${selectedElement.dataset.id}
        data-name=${selectedElement.dataset.name}>Add</a>`
      ])
      .draw()

    let existingStoreIds = elements.listingStoreIds.value

    if (existingStoreIds === '') {
      existingStoreIds = []
    } else {
      existingStoreIds = JSON.parse(existingStoreIds)
    }

    const itemIndex = existingStoreIds.indexOf(selectedElement.dataset.id)
    existingStoreIds.splice(itemIndex, 1)

    if (existingStoreIds.length) {
      existingStoreIds = JSON.stringify(existingStoreIds)
    } else {
      existingStoreIds = ''
    }

    elements.listingStoreIds.value = existingStoreIds
    elements.associatedStoreIds.value = existingStoreIds
  }

  const openStoreModal = (event) => {
    const configurationValue = $('#listing_section_configuration_type').val()
    if (elements.selectedServiceType.value === '' && configurationValue === 'special_stores') {
      event.preventDefault()
      alert('Please select servise type first')
    } else {
      event.target.disabled = true
      event.target.innerHTML = 'Please wait...'

      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: '/app_display/listing_carousel_sections/associate_stores_modal',
        data: {
          existing_store_ids: elements.listingStoreIds.value,
          service_type_id: elements.selectedServiceType.value
        },
        success: () => initializeModalSelectors()
      })
    }
  }

  const updateListingRanks = () => {
    $.ajax({
      type: 'POST',
      dataType: 'script',
      url: '/app_display/listing_carousel_sections/update_listing_ranks',
      data: {
        listing_sections: elements.sortedListingSections.value,
        service_type_id: elements.rankSelectedServiceType.value
      },
      success: () => showSuccessModal()
    })
  }

  const calculateExistingRanks = () => {
    $.ajax({
      type: 'POST',
      url: '/app_display/listing_carousel_sections/calculate_available_ranks',
      data: {
        service_type_id: elements.selectedServiceType.value
      },
      success (response) {
        elements.listingSectionRank.innerHTML = response.options
      }
    })
  }

  const showSuccessModal = () => {
    setTimeout(function () {
      $('#successModal').modal('show')
    }, 1000)
  }

  const reloadPage = (event) => {
    const tabLinks = $('#listing_section_tabs a')
    localStorage.setItem('activeTab', tabLinks.first())
    location.reload()
    elements.pageTitle.innerHTML = 'Listing Carousel sections'
  }

  const associateListingRanks = () => {
    $.ajax({
      type: 'POST',
      dataType: 'script',
      url: '/app_display/listing_carousel_sections/associate_listing_ranks',
      data: {
        service_type_id: $('#listing_service_type_rank').val()
      }
    })
  }

  const restrictInCaseOfEmptyFields = (event) => {
    if (elements.configurationType.value === 'special_stores' &&
        elements.listingStoreIds.value === '') {
      event.preventDefault()
      alert('Please select atleast one store')
    }
  }

  return () => {
    initializeSelectors()
    initializeListeners()
    preSelectActiveTab()
    toggleListingFields()
    initializeSortableListingTable()
    disableRankSubmitBtn()
  }
}

application.register(ListingCarouselSection, import.meta)
export default ListingCarouselSection
